export const selectTitle = {
  powerPlattformCertifications: "Indícanos la certificación",
  azureCertifications: "Indícanos la certificación",
  securityCertifications: "Indícanos la certificación",
  microsoft365Certifications: "Indícanos la certificación",
  crm_erp_det: "¿Con cuál de las siguientes estas más familiarizado?",
  industries: "¿Con qué industria has trabajado por más tiempo?",
  lenguaje1: "¿Con cuál de los siguientes lenguajes de programación te sientes más cómodo? 💻",
  nacionalidad: "Puedes indicarme, ¿en que país resides actualmente?",
  front_frame: "¿Dominas alguno de los siguientes Frameworks?",
  dynamics_ver: "Perfecto ¿y con cuál de las siguientes versiones has trabajado?",
  herramienta_data: "¿Con qué herramientas estás más familiarizado?"
};

export const isMultiSelect = {
  powerPlattformCertifications: true,
  azureCertifications: true,
  securityCertifications: true,
  microsoft365Certifications: true,
  crm_erp_det: true,
  lenguaje1: true,
  front_frame: true,
  dynamics_ver: true,
  herramienta_data: true
}

export const selectOptions = {
  powerPlattformCertifications: [
    {
      label: "Ninguna de las anteriores",
      value: "Ninguna de las anteriores"
    },
    {
      label: "MB-910 Dynamics 365 Fundamentals (CRM)",
      value: "MB-910 Dynamics 365 Fundamentals (CRM)"
    },
    {
      label: "MB-210 Dynamics 365 Sales Functional Consultant Associate",
      value: "MB-210 Dynamics 365 Sales Functional Consultant Associate"
    },
    {
      label: "MB-220 Dynamics 365 Marketing Functional Consultant Associate",
      value: "MB-220 Dynamics 365 Marketing Functional Consultant Associate"
    },
    {
      label: "MB-230 Dynamics 365 Customer Service Functional Consultant Associate",
      value: "MB-230 Dynamics 365 Customer Service Functional Consultant Associate"
    },
    {
      label: "MB-240 Dynamics 365 Field Service Functional Consultant Associate",
      value: "MB-240 Dynamics 365 Field Service Functional Consultant Associate"
    },
    {
      label: "MB-260 Microsoft Customer Data Platform Specialist",
      value: "MB-260 Microsoft Customer Data Platform Specialist"
    },
    {
      label: "PL-900 Power Platform Fundamentals",
      value: "PL-900 Power Platform Fundamentals"
    },
    {
      label: "PL-100 Power Platform Developer Associate",
      value: "PL-100 Power Platform Developer Associate"
    },
    {
      label: "PL-200 Power Platform Functional Consultant Associate",
      value: "PL-200 Power Platform Functional Consultant Associate"
    },
    {
      label: "PL-300 Power BI Data Analyst Associate",
      value: "PL-300 Power BI Data Analyst Associate"
    },
    {
      label: "PL-400 Power Platform App Maker Associate",
      value: "PL-400 Power Platform App Maker Associate"
    },
    {
      label: "PL-600 Power Platform Solution Architect Expert",
      value: "PL-600 Power Platform Solution Architect Expert"
    },
    {
      label: "MB-920 Dynamics 365 Fundamentals (ERP)",
      value: "MB-920 Dynamics 365 Fundamentals (ERP)"
    },
    {
      label: "MB-300 Dynamics 365 Core Finance & Operations",
      value: "MB-300 Dynamics 365 Core Finance & Operations"
    },
    {
      label: "MB-310 Dynamics 365 Finance Functional Consultant Associate",
      value: "MB-310 Dynamics 365 Finance Functional Consultant Associate"
    },
    {
      label: "MB-320 Dynamics 365 Supply Chain Management, Manufacturing",
      value: "MB-320 Dynamics 365 Supply Chain Management, Manufacturing"
    },
    {
      label: "MB-330 Dynamics 365 Supply Chain Management Functional Consultant Associate",
      value: "MB-330 Dynamics 365 Supply Chain Management Functional Consultant Associate"
    },
    {
      label: "MB-340 Dynamics 365 Commerce Functional Consultant Associate",
      value: "MB-340 Dynamics 365 Commerce Functional Consultant Associate"
    },
    {
      label: "MB-500 Dynamics 365: Finance and Operations Apps Developer Associate",
      value: "MB-500 Dynamics 365: Finance and Operations Apps Developer Associate"
    },
    {
      label: "MB-700 Dynamics 365: Finance and Operations Apps Solution Architect Expert",
      value: "MB-700 Dynamics 365: Finance and Operations Apps Solution Architect Expert"
    },
    {
      label: "MB-800 Dynamics 365 Business Central Functional Consultant Associate",
      value: "MB-800 Dynamics 365 Business Central Functional Consultant Associate"
    }
  ],
  azureCertifications: [
    {
      label: "Ninguna de las anteriores",
      value: "Ninguna de las anteriores"
    },
    {
      label: "AZ-400 Azure DevOps Engineer Expert",
      value: "AZ-400 Azure DevOps Engineer Expert"
    },
    {
      label: "DP-203 Azure Data Engineer Associate",
      value: "DP-203 Azure Data Engineer Associate"
    },
    {
      label: "AZ-104 Azure Administrator Associate",
      value: "AZ-104 Azure Administrator Associate"
    },
    {
      label: "AI-102 Azure AI Engineer Associate",
      value: "AI-102 Azure AI Engineer Associate"
    },
    {
      label: "AZ-700 Azure Network Engineer Associate",
      value: "AZ-700 Azure Network Engineer Associate"
    },
    {
      label: "DP-500 Azure Enterprise Data Analyst Associate",
      value: "DP-500 Azure Enterprise Data Analyst Associate"
    },
    {
      label: "AZ-204 Azure Developer Associate",
      value: "AZ-204 Azure Developer Associate"
    },
    {
      label: "AZ-500 Azure Security Engineer Associate",
      value: "AZ-500 Azure Security Engineer Associate"
    },
    {
      label: "SC-300 Identity and Access Administrator Associate",
      value: "SC-300 Identity and Access Administrator Associate"
    },
    {
      label: "DP-100 Azure Data Scientist Associate",
      value: "DP-100 Azure Data Scientist Associate"
    },
    {
      label: "DP-300 Azure Database Administrator Associate",
      value: "DP-300 Azure Database Administrator Associate"
    },
    {
      label: "AZ-104 Azure Administrator Associate",
      value: "AZ-104 Azure Administrator Associate"
    },
    {
      label: "AZ-305 Azure Solutions Architect Expert",
      value: "AZ-305 Azure Solutions Architect Expert"
    }
  ],
  securityCertifications: [
    {
      label: "Ninguna de las anteriores",
      value: "Ninguna de las anteriores"
    },
    {
      label: "SC-300 Identity and Access Administrator Associate",
      value: "SC-300 Identity and Access Administrator Associate"
    },
    {
      label: "AZ-500 Azure Security Engineer Associate",
      value: "AZ-500 Azure Security Engineer Associate"
    },
    {
      label: "SC-100 Cybersecurity Architect Expert",
      value: "SC-100 Cybersecurity Architect Expert"
    },
    {
      label: "MS-500 Security Administrator Associate",
      value: "MS-500 Security Administrator Associate"
    },
    {
      label: "SC-200 Security Operations Analyst Associate",
      value: "SC-200 Security Operations Analyst Associate"
    },
    {
      label: "MS-203 Messaging Administrator Associate",
      value: "MS-203 Messaging Administrator Associate"
    }

  ],
  microsoft365Certifications: [
    {
      label: "Ninguna de las anteriores",
      value: "Ninguna de las anteriores"
    },
    {
      label: "MS-500 Security Administrator Associate",
      value: "MS-500 Security Administrator Associate"
    },
    {
      label: "MS-740 Teams Support Engineer Specialty",
      value: "MS-740 Teams Support Engineer Specialty"
    },
    {
      label: "MS-100/101 Enterprise Administrator Expert",
      value: "MS-100/101 Enterprise Administrator Expert"
    },
    {
      label: "MS-600 Teams Application Developer Associate",
      value: "MS-600 Teams Application Developer Associate"
    },
    {
      label: "MS-700 Teams Administrator Associate",
      value: "MS-700 Teams Administrator Associate"
    },
    {
      label: "SC-400 Information Protection Administrator Associate",
      value: "SC-400 Information Protection Administrator Associate"
    },
    {
      label: "MS-203 Messaging Administrator Associate",
      value: "MS-203 Messaging Administrator Associate"
    }
  ],
  industries: [
    {
      label: "Automotriz",
      value: "Automotriz"
    },
    {
      label: "Financiera",
      value: "Financiera"
    },
    {
      label: "Retail",
      value: "Retail"
    },
    {
      label: "Gobierno",
      value: "Gobierno"
    },
    {
      label: "Educación",
      value: "Educación"
    },
    {
      label: "Energía",
      value: "Energía"
    },
    {
      label: "Sanidad",
      value: "Sanidad"
    },
    {
      label: "Manufactura",
      value: "Manufactura"
    },
    {
      label: "Medios de comunicación y entretenimiento",
      value: "Medios de comunicación y entretenimiento"
    },
    {
      label: "ONG",
      value: "ONG"
    },
    {
      label: "Telecomunicaciones",
      value: "Telecomunicaciones"
    },
    {
      label: "IT",
      value: "IT"
    },
    {
      label: "Otros",
      value: "Otros"
    }
  ],
  crm_erp_det: [
    {
      label: "Salesforce",
      value: "Salesforce"
    },
    {
      label: "SAP",
      value: "SAP"
    },
    {
      label: "Oracle",
      value: "Oracle"
    },
    {
      label: "SugarCRM",
      value: "SugarCRM"
    },
    {
      label: "Apptivo",
      value: "Apptivo"
    },
    {
      label: "Creatio",
      value: "Creatio"
    },
    {
      label: "Zoho",
      value: "Zoho"
    },
    {
      label: "Netsuite",
      value: "Netsuite"
    },
    {
      label: "Infor",
      value: "Infor"
    },
    {
      label: "Epicor",
      value: "Epicor"
    },
    {
      label: "Siigo",
      value: "Siigo"
    },
    {
      label: "Sage",
      value: "Sage"
    },
    {
      label: "Totvs",
      value: "Totvs"
    },
    {
      label: "Otras",
      value: "Otras"
    }
  ],
  lenguaje1: [
    {
      label: 'C++',
      value: 'C++'
    },
    {
      label: 'Javascript',
      value: 'Javascript'
    },
    {
      label: 'Python',
      value: 'Python'
    },
    {
      label: 'C# / .NET',
      value: 'C# / .NET'
    },
    {
      label: 'C/AL',
      value: 'C/AL'
    },
    {
      label: 'Java',
      value: 'Java'
    },
    {
      label: 'ABAP',
      value: 'ABAP'
    },
    {
      label: 'X++',
      value: 'X++'
    },
    {
      label: 'Otros',
      value: 'Otros'
    },
    {
      label: 'Ninguno',
      value: 'Ninguno'
    },
  ],
  nacionalidad: [
    {
      "label": "Argentina",
      "value": "Argentina"
    },
    {
      "label": "Bolivia",
      "value": "Bolivia"
    },
    {
      "label": "Brasil",
      "value": "Brasil"
    },
    {
      "label": "Chile",
      "value": "Chile"
    },
    {
      "label": "Colombia",
      "value": "Colombia"
    },
    {
      "label": "Costa Rica",
      "value": "Costa Rica"
    },
    {
      "label": "Cuba",
      "value": "Cuba"
    },
    {
      "label": "Ecuador",
      "value": "Ecuador"
    },
    {
      "label": "El Salvador",
      "value": "El Salvador"
    },
    {
      "label": "Guayana Francesa",
      "value": "Guayana Francesa"
    },
    {
      "label": "Granada",
      "value": "Granada"
    },
    {
      "label": "Guatemala",
      "value": "Guatemala"
    },
    {
      "label": "Guayana",
      "value": "Guayana"
    },
    {
      "label": "Haití",
      "value": "Haití"
    },
    {
      "label": "Honduras",
      "value": "Honduras"
    },
    {
      "label": "Jamaica",
      "value": "Jamaica"
    },
    {
      "label": "México",
      "value": "México"
    },
    {
      "label": "Nicaragua",
      "value": "Nicaragua"
    },
    {
      "label": "Paraguay",
      "value": "Paraguay"
    },
    {
      "label": "Panamá",
      "value": "Panamá"
    },
    {
      "label": "Perú",
      "value": "Perú"
    },
    {
      "label": "Puerto Rico",
      "value": "Puerto Rico"
    },
    {
      "label": "República Dominicana",
      "value": "República Dominicana"
    },
    {
      "label": "Surinam",
      "value": "Surinam"
    },
    {
      "label": "Uruguay",
      "value": "Uruguay"
    },
    {
      "label": "Venezuela",
      "value": "Venezuela"
    },
    {
      "label": "Otro ",
      "value": "Otro "
    }
  ],
  front_frame: [
    {
      "label": "Angular",
      "value": "Angular"
    },
    {
      "label": "React",
      "value": "React"
    },
    {
      "label": ".NET",
      "value": ".NET"
    },
    {
      "label": "Node",
      "value": "Node"
    },
    {
      "label": "No, pero me gustaría aprender",
      "value": "No, pero me gustaría aprender"
    },
    {
      "label": "Otros",
      "value": "Otros"
    }
  ],
  dynamics_ver: [
    {
      "label": "Dynamics 365 CE (CRM)",
      "value": "Dynamics 365 CE (CRM)"
    },
    {
      "label": "Dynamics 365 F&O (AX)",
      "value": "Dynamics 365 F&O (AX)"
    },
    {
      "label": "Dynamics 365 Business Central (NAV/Navision)",
      "value": "Dynamics 365 Business Central (NAV/Navision)"
    },
    {
      "label": "Dynamics 365 GP",
      "value": "Dynamics 365 GP"
    },
    {
      "label": "Dynamics 365 SL",
      "value": "Dynamics 365 SL"
    },
    {
      "label": "Microsoft Power Platform",
      "value": "Microsoft Power Platform"
    }
  ],
  herramienta_data: [
    {
      "label": "IBM Watson Analytics",
      "value": "IBM Watson Analytics"
    },
    {
      "label": "Microsoft Power BI",
      "value": "Microsoft Power BI"
    },
    {
      "label": "Google Data Studio",
      "value": "Google Data Studio"
    },
    {
      "label": "Tableau",
      "value": "Tableau"
    },
    {
      "label": "BIRT",
      "value": "BIRT"
    },
    {
      "label": "Jaspersoft",
      "value": "Jaspersoft"
    },
    {
      "label": "Pentaho",
      "value": "Pentaho"
    },
    {
      "label": "Otras",
      "value": "Otras"
    }
  ]
};